var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userData)?_c('div',{staticClass:"text-center pr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.avatar))])])],1)]}}],null,false,1250361532),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.avatar))])]),(_vm.loaded)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('h4',[_vm._v(" "+_vm._s(_vm.userData.first_name + " " + _vm.userData.last_name)+" ")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.activeOrganizationName))])],1):_vm._e()],1)],1),_c('v-divider'),_c('v-list',[(_vm.activeOrganizationName && _vm.organizationData.length > 1)?_c('v-list-item',[_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-swap-horizontal")]),_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","tile":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.activeOrganizationName))])]}}],null,false,615384910)},[_c('v-list',{staticStyle:{"max-height":"310px","overflow-y":"auto"}},[_c('v-list-item',[_c('v-text-field',{staticClass:"ma-6 my-3",attrs:{"append-icon":"mdi-magnify","label":"Zoek","single-line":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._l((_vm.filteredOrganizations),function(organization){return _c('v-list-item',{key:organization.slug,attrs:{"to":{
                  name: 'dashboard',
                  params: { org: organization.slug }
                }},on:{"click":function () {
                    _vm.$store.dispatch('dashboard/clearBreadcrumbs');
                    _vm.search = '';
                  }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-swap-horizontal")]),_c('v-btn',{attrs:{"text":"","tile":"","small":""}},[_vm._v(_vm._s(organization.name))])],1)})],2)],1)],1):_vm._e(),(_vm.userData.dashboard_type != 'viewer')?_c('v-list-item',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-cog")]),_c('v-btn',{attrs:{"to":{
              name: 'settings',
              params: { org: _vm.activeOrganizationSlug }
            },"text":"","tile":"","small":""}},[_vm._v("Instellingen")])],1):_vm._e(),_c('v-list-item',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-logout")]),_c('v-btn',{attrs:{"name":"logout","text":"","tile":"","small":""},on:{"click":function($event){return _vm.$router.push('/logout')}}},[_vm._v("Uitloggen")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }