var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-12"},[_c('v-col',{staticClass:"pa-12",attrs:{"cols":"12"}},[_c('TabDescription',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Baseline ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Op basis van een bestek of een hoeveelhedenstaat wordt een MKI-referentieberekening gemaakt. Eco Monitor gebruikt hiervoor een rekenmodule waarmee eenvoudig en uniform een representatieve referentie wordt berekend. De rekenmodule maakt inzichtelijk wat de totale MKI-waarde is voor het uitvoeren van het project met betrekking tot de gestelde scope, opgesplitst naar modules en individuele werkzaamheden. Naast de referentie MKI worden de referentiewaardes van verschillende beleidsdoelstellingen getoond. ")]},proxy:true},{key:"description2",fn:function(){return [_vm._v(" Onderstaand kunnen de verschillende baseline berekeningen worden toegevoegd. ")]},proxy:true}])}),_c('BaselineDatatable'),_c('v-divider',{staticClass:"my-12"}),_c('transition',{attrs:{"name":"slide-down","mode":"out-in"}},[(_vm.hasBaselineCalculations)?_c('div',[_c('Charts'),(
            _vm.currentProject.project_status !== 'Design_Phase_Complete' &&
              _vm.currentProject.project_status !== 'Project_Complete' &&
              !_vm.currentProject.project_locked
          )?_c('v-divider',{staticClass:"mt-8"}):_vm._e()],1):_vm._e()]),(
        _vm.currentProject.project_status !== 'Design_Phase_Complete' &&
          _vm.currentProject.project_status !== 'Project_Complete' &&
          !_vm.currentProject.project_locked
      )?_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-right"},[_c('v-spacer'),(_vm.isBaselineComplete)?_c('v-btn',{staticClass:"border-radius-20 mb-3 px-4",attrs:{"depressed":"","large":"","color":"secondary"},on:{"click":_vm.nextTab}},[_vm._v(" Volgende ")]):_c('v-btn',{staticClass:"border-radius-20 mb-3 px-4",attrs:{"depressed":"","large":"","disabled":""},on:{"click":_vm.nextTab}},[_vm._v(" "+_vm._s(_vm.buttonMessage)+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }