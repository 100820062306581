<template>
  <ValidationObserver v-slot="{ invalid }">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-card class="pa-6 border-radius-20" max-width="500px">
          <v-card-text class="text-center">
            <div id="password-reset-view">
              <template v-if="emailLoading">
                <div style="color: #034173">laden...</div>
              </template>
              <template v-else-if="!emailCompleted">
                <v-img
                  class="mx-auto mb-8"
                  src="@/assets/logo/FullColour/EcoMonitorLogoFullColor.svg"
                  max-width="150px"
                ></v-img>
                <ValidationProvider
                  :rules="{ required: true, email: true }"
                  name="Email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    outlined
                    class="border-radius-textfield"
                    :label="errors[0] ? errors[0] : 'E-mailadres'"
                    :error-messages="errors"
                    hide-details
                    v-model="inputs.email"
                    name="email"
                    v-on:keyup.enter="sendPasswordResetEmail(inputs)"
                /></ValidationProvider>
                <v-card-actions class="justify-center mt-5">
                  <v-btn
                    class="white--text border-radius-textfield"
                    height="50px"
                    ripple
                    depressed
                    :disabled="invalid"
                    block
                    color="secondary"
                    @click="sendPasswordResetEmail(inputs)"
                    >Verstuur email</v-btn
                  >
                </v-card-actions>
                <div class="red--text caption mt-3" v-if="emailError">
                  <div>Er is iets fout gegaan.</div>
                </div>
                <div class="pt-4">
                  <div class="caption text-center">
                    <a @click="$router.push('/login')">Inloggen</a>
                  </div>
                </div>
              </template>
              <template v-else>
                <v-card-text class="pa-6 pb-0 text--primary flex-grow-1">
                  Er is een e-mail verstuurd naar het opgegeven e-mailadres met
                  daarin een link om uw wachtwoord te wijzigen.
                </v-card-text>
                <v-card-text class="pa-6 pt-0 text--primary flex-grow-1">
                  <router-link to="/login">
                    Naar inloggen
                  </router-link>
                </v-card-text>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";

export default Vue.extend({
  name: "PasswordReset",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      inputs: { email: "" },
      emailRules: [v => /.+@.+\..+/.test(v) || "Voer een geldig e-mailadres in"]
    };
  },
  computed: mapState("password", [
    "emailCompleted",
    "emailError",
    "emailLoading"
  ]),
  methods: mapActions("password", [
    "sendPasswordResetEmail",
    "clearEmailStatus"
  ]),
  beforeRouteLeave(to, from, next) {
    this.clearEmailStatus();
    next();
  }
});
</script>
