<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="py-2 mt-3 primary text--white">
        <v-row justify="start" align="start">
          <v-col cols="5" class="pb-5">
            <v-card class=" primary text--white" elevation="0" tile>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="headline mb-4 mt-7 font-weight-black text--white"
                    style="color: white;"
                  >
                    Veelgestelde vragen
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class=" pa-8 mb-6">
      <v-col cols="12">
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Zoek naar een antwoord op uw vraag"
            single-line
          ></v-text-field>
        </v-toolbar>
        <v-card
          v-for="(item, i) in searching"
          :key="i"
          @click="() => {}"
          outlined
          elevation="1"
          class="rounded-xl py-6 px-6 mb-3 card-hover"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption mb-2 ">
                {{ item.faq_category }}
              </v-list-item-subtitle>
              <v-list-item-title
                class="headline mb-2 font-weight-bold text--primary"
              >
                <h4 class="font-weight-bold">{{ item.faq_title }}</h4>
              </v-list-item-title>

              <v-list-item-subtitle class="mb-2 v-card__text">
                {{ item.faq_content }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    search: ""
  }),

  computed: {
    ...mapState("organizations", ["organizationData"]),
    searching() {
      if (!this.search) return this.$store.state.faq.answers;

      const search = this.search.toLowerCase();

      return this.$store.state.faq.answers.filter(item => {
        const text = item.faq_title.toLowerCase();

        return text.indexOf(search) > -1;
      });
    }
  },
  mounted() {
    this.$store.dispatch("faq/loadFaqData");
  }
});
</script>
