var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-6 mb-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 primary text--white",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6 pb-0 primary text--white",attrs:{"elevation":"0","tile":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:" mb-3 font-weight-black text--white",staticStyle:{"color":"white"}},[(_vm.currentProject.project_locked)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-lock")]):_vm._e(),_c('h3',{staticClass:"font-weight-black white--text"},[_vm._v(" "+_vm._s(_vm.currentProject.project_name)+" ")])],1),_c('div',{staticClass:"overline text--white",staticStyle:{"color":"white"}},[_vm._v(" Projectstatus:"),(_vm.currentProject.project_status_display)?_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.currentProject.project_status_display))]):_vm._e()])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pb-5",attrs:{"justify":"start","align":"end"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"dark":"","color":"white","background-color":"primary"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-spacer'),_vm._l((_vm.adjustedTabs),function(tab){return _c('v-tab',{key:tab.index,staticClass:"font-weight-black text",attrs:{"disabled":tab.status === 'Excluded' || tab.status === 'Disabled'},on:{"click":function($event){return _vm.setActiveTab(tab)}}},[(
                  (_vm.currentProject.project_status ===
                    'Design_Phase_Complete' &&
                    tab.name !== 'As-built') ||
                    _vm.currentProject.project_locked
                )?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-lock")]):(
                  _vm.currentProject.project_status === 'Project_Complete' ||
                    _vm.currentProject.project_locked
                )?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-lock")]):_c('v-img',{staticClass:"mr-2",attrs:{"width":tab.icon_size,"src":tab.icon}}),_vm._v(" "+_vm._s(tab.name)+" ")],1)})],2)],1)],1)],1)],1),_c('transition-group',{attrs:{"name":"tab-fade","tag":"div"}},[(_vm.activeTab)?_c(_vm.activeTab.component,{key:_vm.activeTab.name,tag:"component",attrs:{"tab-type":_vm.activeTab.status,"tab-type-asbuilt":_vm.asbuiltTab},on:{"change-tab":_vm.handleTabChangeOrCompletePhase}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }