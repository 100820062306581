var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"pa-12"},[_c('v-col',{staticClass:"pa-12",attrs:{"cols":"12"}},[_c('TabDescription',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Projectgegevens ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Hieronder ziet u de projectgegevens en projectleden voor het project "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.currentProject.project_name))]),_vm._v(" staan. U kunt de projectgegevens aanpassen en deelnemers toevoegen of verwijderen door op "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("wijzigen")]),_vm._v(" te klikken. ")]},proxy:true}],null,true)}),_c('v-card',{staticClass:"ma-8 pa-8 border-radius-20",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"overline m-4"},[_vm._v(" "+_vm._s(_vm.currentProject.project_name)+" - "+_vm._s(_vm.currentProject.project_contract_type_display)+" ")]),_c('v-list-item-title',{staticClass:"mb-1"},[_c('h3',[_vm._v("Projectgegevens")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card-text',{staticClass:"pr-0 pb-0"},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"projectname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-20",attrs:{"disabled":_vm.disableDpcPc,"error-messages":errors,"hide-details":"","label":invalid ? errors[0] : 'Projectnaam*',"append-icon":"mdi-file-document","outlined":""},model:{value:(_vm.currentProject.project_name),callback:function ($$v) {_vm.$set(_vm.currentProject, "project_name", $$v)},expression:"currentProject.project_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"projectmanager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-20",attrs:{"disabled":_vm.disableDpcPc,"error-messages":errors,"hide-details":"","label":invalid ? errors[0] : 'Projectmanager*',"append-icon":"mdi-account","outlined":""},model:{value:(_vm.currentProject.project_leader),callback:function ($$v) {_vm.$set(_vm.currentProject, "project_leader", $$v)},expression:"currentProject.project_leader"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"border-radius-20",attrs:{"error-messages":errors,"disabled":_vm.disableDpcPc,"label":invalid ? errors[0] : 'Sector*',"outlined":"","hide-details":"","items":[
                        'Infrastructuur hoofdwegennet A-wegen',
                        'Infrastructuur hoofdwegennet N-wegen',
                        'Infrastructuur binnenstedelijk',
                        'Infrastructuur grote waterwegen en kanalen',
                        'Infrastructuur waterschappen',
                        'Infrastructuur railbouw',
                        'Kustlijn verzorging',
                        'Groenbeheer',
                        'Energie',
                        'Textiel',
                        'Vastgoed',
                        'Kantoor inrichting en kantoormeubilair',
                        'Voedsel en dranken',
                        'ICT',
                        'Overige infra'
                      ],"placeholder":"Sector*","required":"","append-icon":"mdi-shape"},model:{value:(_vm.currentProject.sector_display),callback:function ($$v) {_vm.$set(_vm.currentProject, "sector_display", $$v)},expression:"currentProject.sector_display"}})]}}],null,true)})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card-text',{staticClass:"pr-0  pb-0"},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"projectnumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-20",attrs:{"disabled":_vm.disableDpcPc,"error-messages":errors,"hide-details":"","label":invalid ? errors[0] : 'Projectnummer*',"append-icon":"mdi-file-document-multiple","outlined":""},model:{value:(_vm.currentProject.internal_project_number),callback:function ($$v) {_vm.$set(_vm.currentProject, "internal_project_number", $$v)},expression:"currentProject.internal_project_number"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"projectlocatie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-20",attrs:{"disabled":_vm.disableDpcPc,"error-messages":errors,"hide-details":"","label":invalid ? errors[0] : 'Projectlocatie*',"append-icon":"mdi-map-marker","outlined":""},model:{value:(_vm.currentProject.project_location),callback:function ($$v) {_vm.$set(_vm.currentProject, "project_location", $$v)},expression:"currentProject.project_location"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{staticClass:"border-radius-20",attrs:{"disabled":_vm.disableDpcPc,"append-icon":"mdi-account-multiple","outlined":"","counter":"500","maxlength":"500"},model:{value:(_vm.currentProject.project_description),callback:function ($$v) {_vm.$set(_vm.currentProject, "project_description", $$v)},expression:"currentProject.project_description"}})],1)],1)],1)],1),_c('v-col',{staticStyle:{"border-left":"1px dotted gainsboro"},attrs:{"cols":"4"}},[_c('v-card-text',_vm._l((_vm.currentProjectmembers),function(currentProjectmember){return _c('v-row',{key:currentProjectmember.uuid,staticClass:"mt-0 mb-3",attrs:{"cols":"12","md":"4"}},[_c('v-item-group',{attrs:{"multiple":""}},[_c('v-item',[_c('v-card',{staticClass:"d-flex align-center border-radius-20",attrs:{"disabled":_vm.disabled,"outlined":"","elevation":"0"}},[_c('v-list-item',{staticClass:"mb-2",attrs:{"three-line":""}},[_c('v-list-item-content',{staticStyle:{"color":"#011c40"}},[_c('div',{staticClass:"overline mb-2"},[_vm._v(" "+_vm._s(_vm.organizationData.name)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-2"},[_vm._v(_vm._s(currentProjectmember.user.first_name)+" "+_vm._s(currentProjectmember.user.last_name))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v(_vm._s(currentProjectmember.user.email)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-decoration-none"},[_vm._v(_vm._s(currentProjectmember.user.tel))])],1),_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function () {
                                if (!_vm.disabled) {
                                  _vm.removeProjectmember(
                                    currentProjectmember.user.uuid
                                  );
                                }
                              }}},[_c('v-icon',{style:(_vm.disabled ? 'color: #6b768b' : 'color:red')},[_vm._v(" "+_vm._s(_vm.disabled ? "mdi-account-check" : "mdi-delete")+" ")])],1)],1)],1)],1)],1)],1)],1)}),1),(!_vm.disabled || _vm.currentProjectmembers.length === 0)?_c('v-divider',{staticClass:"mx-2 mt-2"}):_vm._e(),(!_vm.disabled || _vm.currentProjectmembers.length === 0)?_c('v-card-text',{staticClass:"pl-0  pt-2"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v(" + projectleden toevoegen ")]),_c('v-row',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}})],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center"}},[[_c('v-dialog',{attrs:{"overlay-opacity":"0.8","light":"","max-width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"white"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticClass:"text--primary"},[_c('h3',[_vm._v("Projectleden toevoegen")])])],1),_c('v-item-group',{staticClass:"px-8 py-6",staticStyle:{"background":"white"},attrs:{"multiple":""}},[_c('v-container',[_c('v-row',_vm._l((_vm.activeOrganizationMembers),function(organizationmember){return _c('v-col',{key:organizationmember.user.uuid,attrs:{"cols":"12","md":"4"}},[_c('v-item',[_c('v-card',{staticClass:"d-flex align-center border-radius-20",attrs:{"outlined":"","elevation":_vm.projectMemberExists(organizationmember) ? 3 : 0}},[_c('v-list-item',{staticClass:"mb-2",attrs:{"three-line":""}},[_c('v-list-item-content',{staticClass:"not-active-color",class:{
                                'is-active-color': _vm.projectMemberExists(
                                  organizationmember
                                )
                              }},[_c('div',{staticClass:"overline mb-2"},[_vm._v(" "+_vm._s(_vm.organizationData.name)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-2"},[_vm._v(_vm._s(organizationmember.user.first_name)+" "+_vm._s(organizationmember.user.last_name))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v(_vm._s(organizationmember.user.email)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-decoration-none"},[_vm._v(_vm._s(organizationmember.user.tel))])],1),_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function () {
                                    if (
                                      _vm.projectMemberExists(organizationmember)
                                    ) {
                                      _vm.removeProjectmember(
                                        organizationmember.user.uuid
                                      );
                                    } else {
                                      _vm.saveProjectmember(
                                        organizationmember.user.uuid,
                                        organizationmember.experience
                                      );
                                    }
                                  }}},[_c('v-icon',{style:(_vm.projectMemberExists(organizationmember)
                                      ? 'color: red'
                                      : 'color:#011c40')},[_vm._v(" "+_vm._s(_vm.projectMemberExists(organizationmember) ? "mdi-delete" : "mdi-account-plus")+" ")])],1)],1)],1)],1)],1)],1)}),1),_c('v-divider',{staticClass:"my-8"}),_c('v-row',{staticClass:"mr-0",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"border-radius-20",attrs:{"ripple":"","color":"secondary"},on:{"click":function($event){(_vm.dialog = false), (_vm.snackbar = true)}}},[_vm._v("Opslaan")])],1)],1)],1)],1)]],2),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"align":"end"}},[(!_vm.currentProject.project_locked)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.disabled),expression:"disabled"}],attrs:{"ripple":"","text":"","color":"primary"},on:{"click":function($event){_vm.changeDisabled(), (_vm.snackbar = false)}}},[_vm._v("Wijzigen")]):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],attrs:{"ripple":"","color":"secondary"},on:{"click":function($event){_vm.changeDisabled(), (_vm.snackbar = true), _vm.saveChanges()}}},[_vm._v("Opslaan")]),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"primary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Sluiten ")])]}}],null,true),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }