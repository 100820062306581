<template>
  <div class="home">
    <v-app-bar
      app
      light
      elevation="0"
      height="80"
      class="mt-1 pt-1"
      color="white"
      hide-on-scroll
      v-if="isAuthenticated"
    >
      <v-toolbar-title class="pl-2">
        <router-link
          v-if="activeOrganizationSlug"
          :to="{
            name: 'dashboard',
            params: { org: activeOrganizationSlug }
          }"
          ><v-img
            src="@/assets/logo/FullColour/EcoMonitorLogoFullColor.svg"
            alt="Logo Eco Monitor"
            width="150px"
            style="cursor:pointer"
          ></v-img
        ></router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-list class="py-0" v-if="loaded">
        <v-list-item class="pr-2 py-0">
          <v-list-item-content align="right">
            <v-list-item-title class="font-weight-bold">{{
              userData.first_name + " " + userData.last_name
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-caption">{{
              activeOrganizationName
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <MenuProfilePicture />
    </v-app-bar>
    <v-row class="pa-8 px-9 pb-0">
      <ChartsViewerEnvironmentalData />
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

import ChartsViewerEnvironmentalData from "@/components/charts/ChartsViewerEnvironmentalData";

import Vue from "vue";
import { organizationsMixin } from "@/mixins/organizationsMixin";
import MenuProfilePicture from "@/components/appbar/ProfilePictureMenu.vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "viewerDashboard",
  components: {
    MenuProfilePicture,
    ChartsViewerEnvironmentalData
  },
  mixins: [organizationsMixin],
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState("users", ["userData", "loaded"])
  },
  mounted() {
    document.body.className = "dashboardBg";
    this.loadData();
  },
  watch: {
    // call again the method if the route changes
    $route: "loadData"
  },
  methods: {
    loadData() {
      this.$store.dispatch("dashboard/loadMKIOrganizationStatistics");
      this.$store.dispatch("dashboard/loadEnvironmentalStatistics");
      this.$store.dispatch("dashboard/clearBreadcrumbs");
    }
  }
});
</script>
