<template>
  <ValidationObserver v-slot="{ invalid }">
    <v-card flat>
      <v-card-title class="headline font-weight-black text--primary"
        >{{ title }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pt-1">
            <ValidationProvider ref="contactForm" rules="required">
              <v-textarea
                v-model="localMessage"
                hide-details
                outlined
                label="Bericht"
                auto-grow
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="end">
            <v-btn
              @click="handleSendMessage"
              color="secondary"
              :disabled="invalid"
            >
              Verstuur bericht
            </v-btn>
            <v-snackbar :value="snackbar" :timeout="timeout" color="primary">
              {{ snackbarText }}

              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
                  Sluiten
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "Contactform",
  components: { ValidationObserver, ValidationProvider },
  props: {
    title: {
      type: String,
      default: "Stuur ons een bericht"
    },
    invalid: {},
    message: {},
    snackbar: {},
    snackbarText: {},
    timeout: {}
  },
  data() {
    return {
      localMessage: this.message
    };
  },
  methods: {
    handleSendMessage() {
      this.$emit("send", this.localMessage);
      this.resetMessage(); // Reset the message here
      this.$emit("update:snackbar", true); // Emit an event instead of mutating the prop
    },
    resetMessage() {
      this.localMessage = "";
    },
    closeSnackbar() {
      this.$emit("update:snackbar", false); // Emit an event for closing snackbar
    }
  },
  watch: {
    message(newVal) {
      this.localMessage = newVal;
    }
  }
};
</script>
