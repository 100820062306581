var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"primary","mini-variant":_vm.mini,"expand-on-hover":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-img',{staticClass:"ml-2 mb-5",attrs:{"height":"360","position":"bottom left","src":require("@/assets/elements/ripple/Ripple.svg")}})]},proxy:true}],null,false,3685993616),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.activeOrganizationSlug)?_c('v-list',{attrs:{"dense":""}},[(_vm.activeOrganizationSlug)?_c('v-list-item',{attrs:{"to":{
        name: 'dashboard',
        params: { org: _vm.activeOrganizationSlug }
      }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-domain")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.activeOrganizationName)+" ")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"to":{
        name: 'settings',
        params: { org: _vm.activeOrganizationSlug }
      }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Instellingen ")])],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'faq',
        params: { org: _vm.activeOrganizationSlug }
      }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-help-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" FAQ ")])],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'contact',
        params: { org: _vm.activeOrganizationSlug }
      }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Contact ")])],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }