<template>
  <div class="home px-2">
    <LaatsteProjectenBar />
    <v-row class="pa-8 px-9 pb-0">
      <h2>Statistieken</h2>
      <v-spacer></v-spacer>
    </v-row>
    <v-tabs
      class="pa-8 pb-0"
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab>
        Milieudata
      </v-tab>
    </v-tabs>
    <v-divider class="mx-9"></v-divider>
    <v-tabs-items class="pa-8 pt-3" v-model="tab">
      <v-tab-item>
        <ChartsEnvironmentalData />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// @ is an alias to /src
import LaatsteProjectenBar from "@/components/projects/BarLastProjects";
import ChartsEnvironmentalData from "@/components/charts/ChartsEnvironmentalData";

import Vue from "vue";
import { organizationsMixin } from "@/mixins/organizationsMixin";

export default Vue.extend({
  name: "Dashboard",
  components: {
    LaatsteProjectenBar,
    ChartsEnvironmentalData
  },
  data: () => ({
    tab: null
  }),
  mixins: [organizationsMixin],
  mounted() {
    document.body.className = "dashboardBg";
    this.loadData();
  },
  watch: {
    // call again the method if the route changes
    $route: "loadData"
  },
  methods: {
    loadData() {
      this.$store.dispatch("dashboard/loadMKIOrganizationStatistics");
      this.$store.dispatch("dashboard/loadMKISectorStatistics");
      this.$store.dispatch("dashboard/loadEnvironmentalStatistics");
      this.$store.dispatch("dashboard/loadEnvironmentalRatioStatisticsFilters");

      this.$store.dispatch("newProject/requestSectorOptions");
      this.$store.dispatch("dashboard/clearBreadcrumbs");
      this.$store.dispatch("project/clearCurrentProject");
    }
  }
});
</script>
