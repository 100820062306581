<template>
  <div class="home">
    <ProjectTabs />
  </div>
</template>

<script>
// @ is an alias to /src
import ProjectTabs from "@/components/projects/ProjectTabs";
import Vue from "vue";

export default Vue.extend({
  name: "Project",
  components: {
    ProjectTabs
  }
});
</script>
