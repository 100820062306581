var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 2,"edit-icon":"mdi-check","step":"2","editable":_vm.EMVIStatus !== 'Unlocked',"rules":[function () { return !invalid; }],"color":"primary"}},[_c('span',[_vm._v("Verdelen van totale fictieve korting")]),_c('small',{staticClass:"mt-1"},[_vm._v("Verdeel de totale fictieve korting over de gunningscriteria.")])]),_c('v-stepper-content',{staticStyle:{"border-left":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"step":"2"}},[_c('v-card',{staticClass:"mb-6 pa-8 border-radius-textfield",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" Project: "+_vm._s(_vm.currentProject.project_name)+" ")]),_c('v-list-item-title',{staticClass:" mb-1 font-weight-black text--primary"},[_c('h3',[_vm._v("Verdelen van totale fictieve korting")])])],1),_c('ProgressCircular',{attrs:{"step":_vm.currentStep,"lock":_vm.currentProject.project_locked,"total-steps":5}})],1),_c('v-card-text',{staticClass:" pb-0"},[_c('p',{staticClass:"v-card__text py-0"},[_vm._v(" In deze stap verdeelt u het totaal aan fictieve korting gespecificeerd in stap 1 over de verschillende gunningscriteria. ")]),_c('p',{staticClass:"v-card__text py-0 "},[_vm._v(" In stap 4 Simulatie ziet u de effecten van de verdeling van kortingen over de gunningscriteria. U kunt dan zien of deze effecten gewenst zijn. ")])]),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"title font-weight-light mb-2 mt-4 primary--text font-weight-bold"},[_vm._v(" Totaal resterende fictieve korting: € "+_vm._s(_vm.tenderBenefit)+",- ")]),_c('v-progress-linear',{attrs:{"color":_vm.progressBar <= 33
                        ? 'red darken-4'
                        : _vm.progressBar < 59
                        ? 'red darken-2'
                        : _vm.progressBar < 80
                        ? 'orange darken-4'
                        : _vm.progressBar < 95
                        ? 'orange darken-1'
                        : _vm.progressBar < 100
                        ? 'orange lighten-1'
                        : _vm.progressBar === 100
                        ? 'green'
                        : 'red',"height":"10","value":_vm.progressBar,"striped":"","rounded":""}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text--primary"},[_vm._v("Criteria")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"text--primary"},[_vm._v("Maximale fictieve korting")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"custom-label-color border-radius-textfield",attrs:{"label":"MKI","background-color":"primary","dark":"","disabled":"","dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"immediate":"","name":"maxDiscountMKI","rules":("required|positive|maxDiscount:" + _vm.tenderBenefit + "|tenderBenefitZero:" + _vm.tenderBenefit)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"label":errors[0],"dense":"","hide-details":"","outlined":"","placeholder":"0","prefix":"€","type":"number","disabled":_vm.disableDpcPc || _vm.disableEMVI},on:{"blur":function($event){return _vm.saveMKIMaxDiscount(_vm.currentProject.mki_max_discount)}},model:{value:(_vm.currentProject.mki_max_discount),callback:function ($$v) {_vm.$set(_vm.currentProject, "mki_max_discount", _vm._n($$v))},expression:"currentProject.mki_max_discount"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"custom-label-color border-radius-textfield",attrs:{"label":"Overige criteria","background-color":"primary","dark":"","disabled":"","dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"immediate":"","name":"maxDiscountMKI","rules":("required|positive|maxDiscount:" + _vm.tenderBenefit + "|tenderBenefitZero:" + _vm.tenderBenefit)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"label":errors[0],"dense":"","hide-details":"","outlined":"","placeholder":"0","prefix":"€","type":"number","disabled":_vm.disableDpcPc || _vm.disableEMVI},on:{"blur":function($event){return _vm.saveCriteriaMaxDiscount(
                          _vm.currentProject.criteria_max_discount
                        )}},model:{value:(_vm.currentProject.criteria_max_discount),callback:function ($$v) {_vm.$set(_vm.currentProject, "criteria_max_discount", _vm._n($$v))},expression:"currentProject.criteria_max_discount"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-0 mt-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"border-radius-20",attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 1}}},[_vm._v("Vorige")]),_c('v-btn',{staticClass:"border-radius-20",attrs:{"disabled":_vm.tenderBenefit !== 0,"color":"secondary","elevation":"0"},on:{"click":function($event){(_vm.currentStep = 3),
                _vm.$store.dispatch('EMVI/randomize'),
                _vm.$store.dispatch('EMVI/statistics')}}},[_vm._v("Volgende")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }