<template>
  <v-card class="mx-auto pa-8" max-width="800">
    <v-card-title class="title font-weight-regular justify-space-between">
      <span class="font-weight-black">{{ currentTitle }}</span>
      <v-avatar
        color="primary"
        class="font-weight-black white--text"
        size="24"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text>
          <v-text-field label="E-mailadres" value=""></v-text-field>
          <span class="caption grey--text text--darken-1">
            Dit is het e-mailadres waarmee u straks inlogt op Eco Monitor.
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <v-text-field label="Wachtwoord" type="password"></v-text-field>
          <v-text-field
            label="Bevestig wachtwoord"
            type="password"
          ></v-text-field>
          <span class="caption grey--text text--darken-1">
            Please enter a password for your account
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item col :value="3">
        <div class="pa-4 text-center">
          <v-img
            class="mb-4"
            contain
            height="128"
            src="@/assets/LogoEcoReview.svg"
          ></v-img>
          <h3 class="font-weight-black">Welkom bij Eco Monitor</h3>
          <span class="caption grey--text"
            >We hebben u een mail gestuurd met uw inloggegevens!</span
          >
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions class="pt-8">
      <v-spacer></v-spacer>
      <v-btn :disabled="step === 1" text @click="step--">
        Vorige
      </v-btn>

      <v-btn
        :disabled="step === 3"
        color="secondary"
        tile
        depressed
        @click="step++"
      >
        Volgende
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    step: 1
  }),

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Meld u aan";
        case 2:
          return "Maak een wachtwoord aan";
        default:
          return "Account aangemaakt";
      }
    }
  }
});
</script>
