var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-12"},[_c('v-col',{staticClass:"pa-12",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-5 font-weight-black"},[_vm._v(" Optimalisatie ")]),_c('p',{staticClass:"v-card__text pa-0"},[_vm._v(" Binnen deze module kunnen de geoptimaliseerde berekeningen ten opzichte van de baseline worden geupload. ")]),(_vm.currentProject.project_contract_type !== 'NA')?_c('p',{staticClass:"v-card__text pa-0 error--text"},[_vm._v(" Indien er geen geoptimaliseerde berekeningen zijn gevraagd in de aanbesteding, dient hier nogmaals de referentieberekening geupload te worden. ")]):_vm._e(),_c('p',{staticClass:"v-card__text pa-0"},[_vm._v(" Vervolgens worden de resultaten ten opzichte van de baseline berekening getoond voor de \"actieve\" berekening ")]),_c('v-divider',{staticClass:"mt-8"}),_c('OptimizationBasicDatatable'),_c('v-divider',{staticClass:"my-12"}),_c('transition',{attrs:{"name":"slide-down","mode":"out-in"}},[(_vm.activeOptimizationCalculation)?_c('div',[_c('Charts'),(
            _vm.currentProject.project_status !== 'Design_Phase_Complete' &&
              _vm.currentProject.project_status !== 'Project_Complete'
          )?_c('v-divider',{staticClass:"mt-8"}):_vm._e()],1):_vm._e()]),(
        _vm.currentProject.project_status !== 'Design_Phase_Complete' &&
          _vm.currentProject.project_status !== 'Project_Complete'
      )?_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-right"},[_c('v-spacer'),(_vm.activeOptimizationCalculation)?_c('v-btn',{staticClass:"border-radius-20 mb-3 px-4",attrs:{"depressed":"","large":"","color":"secondary"},on:{"click":function($event){_vm.showDialog = true}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_c('v-btn',{staticClass:"border-radius-20 mb-3 px-4",attrs:{"depressed":"","large":"","color":"secondary","disabled":""}},[_vm._v(" Selecteer eerst een actieve berekening ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"pa-8 border-radius-textfield",attrs:{"outlined":""}},[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.buttonText))])]),_c('v-card-text',{staticClass:"v-card__text"},[_c('p',[_vm._v(_vm._s(_vm.firstParagraphText))]),_c('p',[_vm._v(_vm._s(_vm.secondParagraphText))]),(this.tabTypeAsbuilt === 'Excluded')?_c('p',[_vm._v(" Weet u zeker dat het project voltooid is? ")]):_vm._e(),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"border-radius-textfield",attrs:{"readonly":"","label":_vm.currentProject.project_contract_type === 'NA'
                        ? 'Datum ontwerpkeuze gereed*'
                        : 'Definitieve gunningsdatum*',"outlined":"","hide-details":"","append-icon":"mdi-calendar"},model:{value:(_vm.formatedDate),callback:function ($$v) {_vm.formatedDate=$$v},expression:"formatedDate"}},'v-text-field',attrs,false),on))]}}],null,false,516945722),model:{value:(_vm.menuProjectPubDate),callback:function ($$v) {_vm.menuProjectPubDate=$$v},expression:"menuProjectPubDate"}},[_c('v-date-picker',{attrs:{"color":"secondary","locale":"nl-nl","no-title":"","max":new Date().toISOString().substr(0, 10)},on:{"input":function($event){(_vm.currentProject.design_phase_complete_date = _vm.design_phase_complete_date),
                      (_vm.menuProjectPubDate = false)}},model:{value:(_vm.design_phase_complete_date),callback:function ($$v) {_vm.design_phase_complete_date=$$v},expression:"design_phase_complete_date"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"border-radius-20",attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v("Annuleren")]),_c('v-btn',{staticClass:"border-radius-20",attrs:{"elevation":"0","disabled":_vm.currentProject.design_phase_complete_date === null,"color":"secondary"},on:{"click":_vm.nextTab}},[_vm._v(" "+_vm._s(_vm.buttonText))])],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }