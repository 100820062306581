var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.8","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"border-radius-20 py-6 px-8 font-weight-bold text-lowercase white--text emButton emGradient",attrs:{"depressed":""},on:{"click":_vm.resetForm}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1 ml-0",attrs:{"color":"white","right":"","dark":""}},[_vm._v("mdi-play")]),_vm._v(" Start nieuw project ")],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"white"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){(_vm.dialog = false), _vm.resetForm()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticClass:"text--primary"},[_c('h3',[_vm._v("Nieuw project")])])],1),_c('v-stepper',{staticClass:"pa-6",style:(_vm.stepperBorderRadius),attrs:{"vertical":""},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 1,"step":"1"}},[_vm._v(" Projectinfo ")]),_c('v-stepper-content',{staticClass:"pt-4",attrs:{"step":"1"}},[_c('v-card',{staticClass:"pt-2",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-container',{staticClass:"py-0"},[_c('h3',{staticClass:"mb-6"},[_vm._v(" Wat zijn de "),_c('strong',[_vm._v("projectgegevens")]),_vm._v("? ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"projectname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"outlined":"","hide-details":"","label":errors[0],"placeholder":"Projectnaam*","append-icon":"mdi-file-document"},model:{value:(_vm.project.project_name),callback:function ($$v) {_vm.$set(_vm.project, "project_name", $$v)},expression:"project.project_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"internal projectumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"outlined":"","hide-details":"","label":errors[0],"placeholder":"Intern projectnummer*","append-icon":"mdi-file-document-multiple"},model:{value:(_vm.project.internal_project_number),callback:function ($$v) {_vm.$set(_vm.project, "internal_project_number", $$v)},expression:"project.internal_project_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"projectleader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"outlined":"","hide-details":"","label":errors[0],"placeholder":"Projectleider*","append-icon":"mdi-account"},model:{value:(_vm.project.project_leader),callback:function ($$v) {_vm.$set(_vm.project, "project_leader", $$v)},expression:"project.project_leader"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"outlined":"","hide-details":"","placeholder":"Project uitvoeringslocatie","append-icon":"mdi-map-marker"},model:{value:(_vm.project.project_location),callback:function ($$v) {_vm.$set(_vm.project, "project_location", $$v)},expression:"project.project_location"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-tooltip',{attrs:{"color":"primary","max-width":"450px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('v-select',_vm._g(_vm._b({staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"label":errors[0],"hide-details":"","outlined":"","items":_vm.getSectorOptions,"item-text":"display_name","placeholder":"Sector*","required":"","return-object":"","append-icon":"mdi-shape"},on:{"input":validate},model:{value:(_vm.newProjectSector),callback:function ($$v) {_vm.newProjectSector=$$v},expression:"newProjectSector"}},'v-select',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('p',{staticClass:"mt-4"},[_vm._v(" Indien het project op meerdere sectoren van toepassing is, kies dan de sector waarbinnen het grootste zwaartepunt van de werkzaamheden binnenvallen. ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-textarea',{staticClass:"border-radius-textfield",attrs:{"clearable":"","auto-grow":"","counter":"500","maxlength":"500","outlined":"","placeholder":"Projectbeschrijving","type":"text","append-icon":"mdi-comment"},model:{value:(_vm.project.project_description),callback:function ($$v) {_vm.$set(_vm.project, "project_description", $$v)},expression:"project.project_description"}})],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"radioGroupContractType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"label":errors[0]},on:{"change":validate},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h3',{staticClass:"mb-4"},[_vm._v(" Welke "),_c('strong',[_vm._v("contractvorm")]),_vm._v(" wilt u hanteren? "),_c('Tooltip',{attrs:{"title":"","content":[
                              'Kies hier de contractvorm die u wil gaan het hanteren bij het aanbesteden van het project. Kies Niet van toepassing indien u enkel berekeningen wil uploaden (bijv. berekeningen in de ontwerpfase)'
                            ]}})],1)]},proxy:true}],null,true),model:{value:(_vm.projectContractType),callback:function ($$v) {_vm.projectContractType=$$v},expression:"projectContractType"}},[_c('v-radio',{attrs:{"value":"RAW"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" UAV contract met RAW bestek ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"value":"UAV-GC"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" UAV-GC contract / Bouwteam ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"value":"NA"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Niet van toepassing ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1),(
                  _vm.project.project_contract_type &&
                    _vm.project.project_contract_type !== 'NA'
                )?_c('v-divider',{staticClass:"my-6"}):_vm._e(),(
                  _vm.project.project_contract_type &&
                    _vm.project.project_contract_type !== 'NA'
                )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"radioGroupAwardCriteria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"label":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h3',{staticClass:"mb-4"},[_vm._v(" Is de berekening onderdeel van het gunningscriterium MKI? "),_c('Tooltip',{attrs:{"title":"","content":[
                              'Het antwoord op deze vraag bepaald de stappen die u hierna dient te doorlopen.'
                            ]}})],1)]},proxy:true}],null,true),model:{value:(_vm.project.project_award_criteria),callback:function ($$v) {_vm.$set(_vm.project, "project_award_criteria", $$v)},expression:"project.project_award_criteria"}},[_c('v-radio',{attrs:{"value":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Ja ")])]},proxy:true}],null,true)}),_c('v-radio',{attrs:{"value":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Nee ")])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)],1):_vm._e()],1)],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',{staticClass:"mr-0 mb-2 mt-3",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"border-radius-20 py-6 px-8 font-weight-bold text-lowercase",attrs:{"ripple":"","color":"secondary","disabled":invalid},on:{"click":function($event){_vm.stepper = 2}}},[_vm._v("Kies projectleden")])],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 2,"step":"2"}},[_c('span',[_vm._v("Kies projectleden")]),(_vm.stepper === 2)?_c('small',{staticClass:"mt-1"},[_vm._v("De gebruiker die het project aanmaakt wordt automatisch toegevoegd als teamlid.")]):_vm._e()]),_c('v-stepper-content',{staticClass:"pt-2",attrs:{"step":"2"}},[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedProjectmembers),callback:function ($$v) {_vm.selectedProjectmembers=$$v},expression:"selectedProjectmembers"}},[_c('v-container',[_c('v-row',_vm._l((_vm.activeOrganizationMembers),function(organizationmember){return _c('v-col',{key:organizationmember.user.uuid,attrs:{"cols":"12","md":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var active = ref.active;
                            var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-xl",attrs:{"outlined":"","elevation":active ? 3 : 0}},[_c('v-list-item',{staticClass:"mb-2",attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-2",style:(active ? 'color: #034173' : 'color:#6b768b')},[_vm._v(" "+_vm._s(_vm.activeOrganizationData.name)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-2",style:(active ? 'color: #034173' : 'color:#6b768b')},[_vm._v(_vm._s(organizationmember.user.first_name)+" "+_vm._s(organizationmember.user.last_name))]),_c('v-list-item-subtitle',{staticClass:"mb-1",style:(active ? 'color: #034173' : 'color:#6b768b')},[_vm._v(_vm._s(organizationmember.user.email)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-decoration-none",style:(active ? 'color: #034173' : 'color:#6b768b')},[_vm._v(_vm._s(organizationmember.user.tel))])],1),_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function () {
                              if (active) {
                                _vm.removeSelectedProjectMember(
                                  organizationmember.user.uuid
                                );
                              } else {
                                _vm.addSelectedProjectMember(organizationmember);
                              }
                              toggle();
                            }}},[_c('v-icon',{style:(active ? 'color: red' : 'color:#03BF8C')},[_vm._v(" "+_vm._s(active ? "mdi-delete" : "mdi-account-plus")+" ")])],1)],1)],1)],1)]}}],null,true)})],1)}),1),_c('v-divider',{staticClass:"my-6 mt-8"})],1)],1),_c('v-row',{staticClass:"mr-0 mb-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"border-radius-20 py-6 px-8 font-weight-bold text-lowercase mr-1",attrs:{"text":""},on:{"click":function($event){_vm.stepper = 1}}},[_vm._v("Vorige")]),_c('v-btn',{staticClass:"border-radius-20 py-6 px-8 font-weight-bold text-lowercase",attrs:{"color":"secondary"},on:{"click":_vm.submitForm}},[_vm._v("Start project "+_vm._s(_vm.project.project_name))])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }