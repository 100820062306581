<template>
  <v-container fluid class="pb-6 mb-8">
    <v-row class="mt-0">
      <v-col cols="12" class="py-0 primary text--white">
        <v-row justify="start" align="start">
          <v-col cols="12">
            <v-card class="pa-6 pb-0 primary text--white" elevation="0" tile>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class=" mb-3 font-weight-black text--white"
                    style="color: white;"
                  >
                    <v-icon
                      v-if="currentProject.project_locked"
                      class="mr-1"
                      color="white"
                      >mdi-lock</v-icon
                    >
                    <h3 class="font-weight-black white--text">
                      {{ currentProject.project_name }}
                    </h3>
                  </v-list-item-title>
                  <div class="overline text--white" style="color: white;">
                    Projectstatus:<span
                      class="white--text"
                      v-if="currentProject.project_status_display"
                    >
                      {{ currentProject.project_status_display }}</span
                    >
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="start" align="end" class="pb-5">
          <v-col cols="12" class="py-0">
            <v-tabs
              dark
              color="white"
              background-color="primary"
              v-model="selected"
            >
              <v-spacer></v-spacer>
              <v-tab
                class="font-weight-black text"
                v-for="tab in adjustedTabs"
                :key="tab.index"
                :disabled="
                  tab.status === 'Excluded' || tab.status === 'Disabled'
                "
                @click="setActiveTab(tab)"
              >
                <v-icon
                  v-if="
                    (currentProject.project_status ===
                      'Design_Phase_Complete' &&
                      tab.name !== 'As-built') ||
                      currentProject.project_locked
                  "
                  class="mr-1"
                  color="grey lighten-1"
                  >mdi-lock</v-icon
                >

                <v-icon
                  v-else-if="
                    currentProject.project_status === 'Project_Complete' ||
                      currentProject.project_locked
                  "
                  class="mr-1"
                  color="grey lighten-1"
                  >mdi-lock</v-icon
                >

                <v-img
                  :width="tab.icon_size"
                  v-else
                  class="mr-2"
                  :src="tab.icon"
                ></v-img>
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <transition-group name="tab-fade" tag="div">
      <component
        v-if="activeTab"
        :is="activeTab.component"
        :key="activeTab.name"
        :tab-type="activeTab.status"
        :tab-type-asbuilt="asbuiltTab"
        @change-tab="handleTabChangeOrCompletePhase"
      ></component>
    </transition-group>
  </v-container>
</template>

<script>
import Project from "@/components/projects/tabs/project/Project";
import Baseline from "@/components/projects/tabs/baseline/Baseline";
import LCA from "@/components/projects/tabs/LCA/LCA";
import AsbuiltMandatory from "@/components/projects/tabs/asbuilt/AsbuiltMandatory.vue";
import AsbuiltOptional from "@/components/projects/tabs/asbuilt/AsbuiltOptional.vue";
import EMVI from "@/components/projects/tabs/emvi/EMVI";
import OptimizationBasic from "@/components/projects/tabs/optimization/OptimizationBasic.vue";
import OptimizationStandard from "@/components/projects/tabs/optimization/OptimizationStandard.vue";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {
      tabConfig: {
        // prettier-ignore
        "RAW": {
          true: {
            Baseline: "Standard",
            Gunningscriterium: "Standard",
            Aanbestedingsdocumenten: "Standard",
            Optimalisatie: "Standard",
            AsBuilt: "Mandatory"
          },
          false: {
            Baseline: "Standard",
            Gunningscriterium: "Excluded",
            Aanbestedingsdocumenten: "Standard",
            Optimalisatie: "Basic",
            AsBuilt: "Mandatory"
          }
        },
        "UAV-GC": {
          true: {
            Baseline: "Standard",
            Gunningscriterium: "Standard",
            Aanbestedingsdocumenten: "Standard",
            Optimalisatie: "Standard",
            AsBuilt: "Optional"
          },
          false: {
            Baseline: "Standard",
            Gunningscriterium: "Excluded",
            Aanbestedingsdocumenten: "Standard",
            Optimalisatie: "Basic",
            AsBuilt: "Optional"
          }
        },
        // prettier-ignore
        "NA": {
          null: {
            Baseline: "Standard",
            Gunningscriterium: "Excluded",
            Aanbestedingsdocumenten: "Excluded",
            Optimalisatie: "Basic",
            AsBuilt: "Excluded"
          }
        }
      },
      tabs: [
        {
          index: 0,
          component: "Project",
          name: "Projectgegevens",
          icon: require("@/assets/icons/gears-solid.svg"),
          icon_size: 24
        },
        {
          index: 1,
          component: "Baseline",
          name: "Baseline",
          icon: require("@/assets/icons/calculator-solid.svg"),
          icon_size: 15
        },
        {
          index: 2,
          component: "EMVI",
          name: "Gunningscriterium",
          icon: require("@/assets/icons/scale-unbalanced-flip-solid.svg"),
          icon_size: 22
        },
        {
          index: 3,
          component: "LCA",
          name: "Aanbestedingsdocumenten",
          icon: require("@/assets/icons/file-contract-solid.svg"),
          icon_size: 15
        },
        {
          index: 4,
          name: "Optimalisatie",
          icon: require("@/assets/icons/pen-fancy-solid.svg"),
          icon_size: 20
        },
        {
          index: 5,
          component: "AsbuiltMandatory",
          name: "As-built",
          icon: require("@/assets/icons/pen-to-square-regular.svg"),
          icon_size: 20
        }
      ],
      selected: 0
    };
  },
  components: {
    Project,
    Baseline,
    LCA,
    OptimizationBasic,
    OptimizationStandard,
    EMVI,
    AsbuiltMandatory,
    AsbuiltOptional
  },
  created() {
    if (this.$route.params.tab) {
      this.selected = Number(this.$route.params.tab) - 1;
    }
  },
  methods: {
    handleTabChangeOrCompletePhase() {
      const asBuiltTab = this.adjustedTabs.find(tab => tab.name === "As-built");
      const OptimizationTab = this.adjustedTabs.find(
        tab => tab.name === "Optimalisatie"
      );

      // Check if the active tab is As-built or if Optimalisatie tab leads to project completion
      if (
        this.activeTab.name === "As-built" ||
        (this.activeTab.name === "Optimalisatie" &&
          asBuiltTab.status === "Excluded")
      ) {
        this.$store.commit("project/SET_PROJECT_STATUS", "Project_Complete");
        this.$store.dispatch("project/updateCurrentProject").then(() => {
          this.$router.push({ name: "dashboard" });
        });
      } else if (
        this.activeTab.name === "Optimalisatie" &&
        asBuiltTab.status !== "Excluded"
      ) {
        this.$store.commit(
          "project/SET_PROJECT_STATUS",
          "Design_Phase_Complete"
        );
        this.$store.dispatch("project/updateCurrentProject").then(() => {
          this.setActiveTab(asBuiltTab);
        });
      } else {
        // Handle moving to the next tab if the project is not completed
        let nextTabIndex = this.selected + 1;
        let nextTabNotDisabled = null;

        while (nextTabIndex < this.adjustedTabs.length) {
          const potentialNextTab = this.adjustedTabs[nextTabIndex];
          if (
            potentialNextTab.status !== "Excluded" &&
            potentialNextTab.status !== "Disabled"
          ) {
            nextTabNotDisabled = potentialNextTab;
            break;
          }
          nextTabIndex++;
        }

        if (nextTabNotDisabled) {
          this.setActiveTab(nextTabNotDisabled);
        }
      }
    },

    setActiveTab(tab) {
      this.selected = tab.index;
      this.$router.replace({
        params: { ...this.$route.params, tab: tab.index + 1 }
      });
    }
  },
  computed: {
    ...mapState("project", ["currentProject"]),
    progress() {
      return this.$store.state.progress;
    },
    activeTab() {
      return this.adjustedTabs.find(item => item.index === this.selected);
    },
    asbuiltTab() {
      return this.adjustedTabs.find(tab => tab.name === "As-built").status;
    },

    adjustedTabs() {
      const contractType = this.currentProject.project_contract_type;
      const awardCriteria = this.currentProject.project_award_criteria;
      const config = this.tabConfig[contractType]?.[awardCriteria];

      if (!config) return this.tabs; // If no configuration exists, show all tabs

      return this.tabs.map(tab => {
        let status = config[tab.name] || "Show";

        // Check if the tab is Optimization and adjust its component and name based on the config
        if (tab.name.startsWith("Optimalisatie")) {
          if (config["Optimalisatie"] === "Basic") {
            tab.component = "OptimizationBasic";
            tab.name = "Optimalisatie";
          } else if (config["Optimalisatie"] === "Standard") {
            tab.component = "OptimizationStandard";
            tab.name = "Optimalisatie";
          }
        }

        // Check if the tab is As-built and adjust its component and name based on the config
        if (tab.name.startsWith("As-built")) {
          if (
            (config["AsBuilt"] !== "Excluded" &&
              this.currentProject.project_status === "Design_Phase_Active") ||
            this.currentProject.project_status === "EMVI_Complete"
          ) {
            status = "Disabled";
          } else if (config["AsBuilt"] === "Mandatory") {
            tab.component = "AsbuiltMandatory";
            tab.name = "As-built";
          } else if (config["AsBuilt"] === "Optional") {
            tab.component = "AsbuiltOptional";
            tab.name = "As-built";
          } else if (config["AsBuilt"] === "Excluded") {
            status = "Excluded";
          }
        }

        return {
          ...tab,
          status
        };
      });
    }
  },
  async mounted() {
    await this.$store.dispatch("project/currentProject");
    await Promise.all([this.$store.dispatch("project/currentProjectmembers")]);
  }
});
</script>
<style scoped>
.v-tab--disabled {
  opacity: 0.4; /* optional, if you want to make it slightly transparent */
  text-decoration: line-through;
}
.tab-fade-enter-active,
.tab-fade-leave-active {
  transition: opacity 0.2s;
}

.tab-fade-enter, .tab-fade-leave-to /* starting and ending states */ {
  opacity: 0;
}
</style>
