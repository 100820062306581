var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"emviStep1"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 1,"edit-icon":"mdi-check","step":"1","editable":_vm.EMVIStatus !== 'Unlocked',"rules":[function () { return !invalid; }],"color":"primary"}},[_c('span',[_vm._v("Specificeer de totale fictieve korting")]),_c('small',{staticClass:"mt-1"},[_vm._v("Specificeer de verhouding prijs-gunningcriteria")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mb-6 pa-8 border-radius-20",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" Project: "+_vm._s(_vm.currentProject.project_name)+" ")]),_c('v-list-item-title',{staticClass:" mb-1 font-weight-black text--primary"},[_c('h3',[_vm._v("Specificeer de totale fictieve korting")])])],1),_c('ProgressCircular',{attrs:{"step":_vm.currentStep,"lock":_vm.currentProject.project_locked,"total-steps":5}})],1),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"text--primary mb-3"},[_vm._v(" Projectomvang "),_c('Tooltip',{attrs:{"title":"Projectomvang","content":[
                        "Betreft de geraamde totale kosten voor het\n                    (laten) uitvoeren van het project. Voorafgaand aan het\n                    project wordt een projectraming opgesteld door de\n                    opgrachtgever. De projectraming geeft een inschatting van\n                    de totale kosten voor materialen en het laten uitvoeren\n                    van de werkzaamheden. Een correct geraamde projectomvang\n                    benadert de uiteindelijke 'aanneemsom'."
                      ]}})],1),_c('ValidationProvider',{attrs:{"immediate":"","name":"projectscope","rules":'required|positive'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"hide-details":"","prefix":"€","label":"Geraamd","dense":"","type":"number","outlined":"","disabled":_vm.disableDpcPc || _vm.disableEMVI},on:{"blur":function($event){return _vm.saveProjectScope(_vm.currentProject.projectscope)}},model:{value:(_vm.currentProject.projectscope),callback:function ($$v) {_vm.$set(_vm.currentProject, "projectscope", $$v)},expression:"currentProject.projectscope"}}),_c('span',{staticClass:"error--text v-messages"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"text--primary mb-3"},[_vm._v(" Totaal fictieve korting "),_c('Tooltip',{attrs:{"title":"Totaal fictieve korting","content":[
                        "Betreft de totale fictieve korting van alle gunningscriteria gehanteerd binnen het project. In stap 2 dient het totale bedrag nader te worden gespecificeerd."
                      ]}})],1),_c('ValidationProvider',{attrs:{"immediate":"","name":"tenderBenefit","rules":"required|positive|tenderBenefitRule:@projectscope"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"error-messages":errors,"label":"Absoluut","dense":"","hide-details":"","outlined":"","prefix":"€","type":"number","disabled":_vm.disableDpcPc || _vm.disableEMVI},on:{"blur":function($event){return _vm.saveFictionalTenderBenefit($event.target.value)}},model:{value:(_vm.currentProject.fictional_tender_benefit),callback:function ($$v) {_vm.$set(_vm.currentProject, "fictional_tender_benefit", _vm._n($$v))},expression:"currentProject.fictional_tender_benefit"}}),_c('span',{staticClass:"error--text v-messages"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4"}},[_c('h3',{staticClass:"text--primary mb-3"},[_c('Tooltip',{attrs:{"title":"%","content":[
                        "Het percentage geeft aan hoeveel het totaal aan fictief gunningsvoordeel ten opzicht van de projectomvang betreft. Voorbeeld: De projectomvang is € 1.000.000 & het fictief gunningsvoordeel is € 300.000, dan verleent u maximaal 30% gunningsvoordeel.",
                        "Het totaal fictief gunningsvoordeel kan kleiner dan de projectomvang zijn (1% - 99%) of gelijk zijn aan de projectomvang (100%). Hoe hoger het percentage, hoe meer waarde u hecht aan de gunningscriteria / kwaliteit / duurzaamheid en hoe minder aan een lage prijs (inschrijfsom)."
                      ]}})],1),_c('v-text-field',{staticClass:"border-radius-textfield",attrs:{"label":"Procentueel","dense":"","outlined":"","prefix":"%","type":"number","disabled":_vm.disableDpcPc || _vm.disableEMVI},model:{value:(_vm.fictionalTenderBenefitPercentage),callback:function ($$v) {_vm.fictionalTenderBenefitPercentage=$$v},expression:"fictionalTenderBenefitPercentage"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-0 mt-6"},[_c('v-spacer'),_c('v-btn',{staticClass:"border-radius-20",attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 2}}},[_vm._v("Vorige")]),_c('v-btn',{staticClass:"border-radius-20",attrs:{"disabled":invalid,"color":"secondary","elevation":"0"},on:{"click":function($event){_vm.currentStep = 2}}},[_vm._v("Volgende")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }