<template>
  <v-row class="px-6">
    <v-col>
      <v-row class="pa-6 pt-3">
        <h1>Agenda</h1>
        <v-spacer></v-spacer>
        <DialogNieuwProject />
      </v-row>
      <v-toolbar flat color="white" class="">
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Vandaag
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'dag'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'maand'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4 dagen'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-sheet height="500" class="px-6">
        <v-calendar
          ref="calendar"
          v-model="value"
          type="week"
          :events="GetEvents"
          color="primary"
          locale="nl"
        >
          <template #day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import DialogNieuwProject from "@/components/projects/NewProjectDialog";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    DialogNieuwProject
  },
  data: () => ({
    today: "2020-07-07",
    type: "month",
    typeToLabel: {
      month: "Maand",
      week: "Week",
      day: "Dagen",
      "4day": "4 Dagen"
    },
    focus: "",
    value: "",
    ready: false
  }),
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    GetEvents() {
      return [
        {
          name: "Aanleggen N264",
          start: "2020-09-29"
        },
        {
          name: "Verbreden weg",
          start: "2020-09-30"
        },
        {
          name: "Onderoud sluis",
          start: "2020-10-01"
        }
      ];
    },
    ...mapState("project", ["allProjects"])
  },
  mounted() {
    this.$refs.calendar.scrollToTime("08:00");
    this.$refs.calendar.checkChange();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  methods: {
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  }
});
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
