<template>
  <ValidationObserver v-slot="{ invalid }">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="py-2 mt-3 primary text--white">
          <v-row justify="start" align="start">
            <v-col cols="5" class="pb-5">
              <v-card class=" primary text--white" elevation="0">
                <v-list-item>
                  <v-list-item-content>
                    <div class="text--white my-4" style="color: white;">
                      Eco Monitor
                    </div>
                    <v-list-item-title
                      class="headline mb-3 font-weight-black text--white"
                      style="color: white;"
                    >
                      Contact
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="pa-8">
        <v-col cols="6">
          <v-card flat>
            <v-card-title class="headline font-weight-black text--primary"
              >Neem contact met ons op</v-card-title
            >
            <v-card-text>
              Heeft u een vraag, een opmerking of een suggestie? Aarzel niet om
              contact met ons op te nemen. U kunt ons bereiken via onderstaande
              gegevens of een bericht sturen via het formulier aan de
              rechterkant. We doen ons best om zo snel mogelijk te reageren.
            </v-card-text>
            <v-card-text>
              <h3 class="font-weight-black text--primary">Eco Monitor</h3>
              <v-icon class="mr-2" color="primary" small
                >mdi-google-maps</v-icon
              >
              Utrecht The Netherlands<br />
              <v-icon class="mr-2" color="primary" small>mdi-phone</v-icon
              ><a class="text-decoration-none" href="tel:+31854008515"
                >+31 (0)85 40 08 515</a
              ><br />
              <v-icon class="mr-2" color="primary" small>mdi-email</v-icon
              ><a class="text-decoration-none" href="mailto:info@eco-monitor.nl"
                >info@eco-monitor.nl</a
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col style="border-left: 1px dotted gainsboro">
          <Contactform
            ref="contactForm"
            :invalid="invalid"
            :message="message"
            @send="
              messageContent =>
                sendMessage(
                  userData.uuid,
                  activeOrganizationName,
                  messageContent
                )
            "
            @update:message="message = $event"
            @update:snackbar="snackbar = $event"
            :snackbar="snackbar"
            :snackbar-text="snackbarText"
            :timeout="timeout"
          />
        </v-col>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { organizationsMixin } from "@/mixins/organizationsMixin";
import Contactform from "@/components/contact/Contactform";

export default Vue.extend({
  components: { Contactform, ValidationObserver, ValidationProvider },
  data() {
    return {
      valid: true,
      files: [],
      items: ["EMVI model", "Referentieberekening", "Invoer data"],
      chips: [],
      message: "",
      snackbar: false,
      snackbarText: "Bericht verstuurd. We nemen z.s.m. contact met u op.",
      timeout: 2500
    };
  },
  mixins: [organizationsMixin],
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    // Inside your methods in the parent component
    sendMessage(user, organisation, messageContent) {
      this.$store
        .dispatch("contact/sendMessage", {
          user,
          organisation,
          message: messageContent
        })
        .then(res => {
          this.message = ""; // Directly reset the message
          this.snackbar = true;
        });
    }
  },
  computed: {
    ...mapState("users", ["userData"])
  }
});
</script>
